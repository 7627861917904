import logo from './logo.gif';
import './App.css';
import {BrowserRouter, Route , Routes} from "react-router-dom"
import Home from './component/Home/Home';

function App() {
  return (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>}  />
      <Route path='/home' element={<Home/>}  />
      <Route path='/project' element={<Home/>}  />
      <Route path='/services' element={<Home/>}  />
      <Route path='/contact' element={<Home/>}  />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
