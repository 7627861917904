import React from 'react'
import "./Home.css"
import image1 from "../../images/default.jpg"
import logo from "../../images/logo.png"
import { Link } from 'react-router-dom'
import {BsInstagram} from "react-icons/bs"
import {TfiTwitter} from "react-icons/tfi"
import {CiLinkedin} from "react-icons/ci"
import {AiOutlineFacebook} from "react-icons/ai"
import {FaTiktok} from "react-icons/fa"
import settings from "../../logo.gif"

export default function Home() {
  return (
    <div className='Home'>
        <div className="right-home">
            <div className="navbar">
                <Link to="/home">
                <img className="logo" src={logo} alt='logo' />
                </Link>
                <Link to="/home">Home</Link>
                <Link to="/project">Projects</Link>
                <Link to="/services">Services</Link>
                <Link to="/contact">Contact</Link>
            </div>
            <div>
            {
                window.location.pathname  ==="/" || window.location.pathname ==="/home"  ?
            <div className="box-img">
            <img className="img1" src={image1} alt="default" />
            </div>:
            <div className='update'>
                <img src={settings} alt='' />
                website update . . .
            </div>
            }

            </div>
            <div className='progress'>
                {window.location.pathname.slice(1)} Page
            </div>
        </div>

        <div className="left-home">
                <div className='name'>Konayel Maxime</div>
                <div className="box-img1">
                    <img className="img2" src={image1} alt="default" />
                 </div>
                <div className='all'>
                    <div className='all1'>
                        <div className="cv"><div className='ok'>o</div> energy systems engineer Bac+4</div>
                        <div className="cv"><div className='ok'>o</div>Web application developer (Reactjs)</div>
                        <div className="cv"><div className='ok'>o</div>Mobile application developer (Flutter)</div>
                        <div className="cv"><div className='ok'>o</div>programming trainer (Reactjs/Python)</div>
                    </div>
                </div>
                <div className='media'>
                    <Link to="/">
                    <BsInstagram />
                    </Link>
                    <Link to="/">
                    <TfiTwitter />
                    </Link>
                    <Link to="/">
                    <CiLinkedin />
                    </Link>
                    <Link to="/">
                    <FaTiktok />
                    </Link>
                </div>
        </div>
        </div>
        
  )
}
